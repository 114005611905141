
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* img, video{
  max-width: none;
} */

.containerr {
  width: 100%;
  height: 100vh;
  text-align: center;
  color: white;
}

.container-autio {
  position: relative;
  top: 50vh;
}

.container-location {
  position: relative;
  top: 20px;
}

.container-location a {
  color: white;
  font-size: 20px;
}

.tinos-regular {
  font-family: "Tinos", serif;
  font-weight: 400;
  font-style: normal;
}

.tinos-bold {
  font-family: "Tinos", serif;
  font-weight: 700;
  font-style: normal;
}

.tinos-regular-italic {
  font-family: "Tinos", serif;
  font-weight: 400;
  font-style: italic;
}

.tinos-bold-italic {
  font-family: "Tinos", serif;
  font-weight: 700;
  font-style: italic;
}
