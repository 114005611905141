.intro {
  width: 100%;
  height: 540px;
  background: url("../../assets/main.png");
  /* background-repeat: no-repeat; */
  display: flex;
  overflow: hidden;
}

.introLeft {
  width: 50%;
}

.introLeftMargin {
  width: 450px;
  height: 300px;
  position: relative;
  color: white;
  top: 120px;
  left: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.introLeftMargin p {
  font-size: 15.39px;
  position: relative;
}

.introLeftMargin p::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 1px;
  background-color: #fff;
  top: 10px;
  margin-left: 5px;
}

.introLeftMargin h1 {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
}

.introLeftMargin div {
  width: 370px;
  height: 140px;
  background-color: #fff;
  color: #1f2732;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  font-size: 14px;
}

.introRight {
  width: 50%;
  height: 100%;
  position: relative;
}

.introRightBtn,
.introRightBtn2,
.introRightBtn3,
.introRightBtn4 {
  width: 237px;
  height: 50px;
  background-color: #fff;
  color: #29879f;
  position: absolute;
  top: 150px;
  right: -50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  font-size: 15px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  transition: 1s;
  font-weight: bold;
}

a {
  color: inherit;
}

.introRightBtn:hover,
.introRightBtn2:hover,
.introRightBtn3:hover,
.introRightBtn4:hover {
  right: 0;
}

.introRightBtn2 {
  top: 210px;
}

.introRightBtn3 {
  top: 270px;
}

.introRightBtn4 {
  top: 330px;
}

.doctorImg {

  position: absolute;
  bottom: 0;
  max-width: none;

}
